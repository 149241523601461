@import (reference) 'styles/app/app.less';

.planning-trade-status-popout.popout-wrapper {
	.approve-trade {
		padding: 16px 12px 12px 12px;

		.approve-trade-header {
			.row;
			justify-content: space-between;

			.approve-trade-header-info {
				color: @dark-grey;
				margin: 4px 0 0 0;
				text-align: right;
				padding: 0 0 0 24px;
			}
		}

		.approve-trade-body {
			.border;
			.border-radius;
			margin: 8px 0 0 0;
			padding: 12px 12px 8px 12px;

			.approve-trade-deal-member {
				.row;
				gap: 12px;
				margin: 12px 0 0 0;

				.approve-trade-deal-member-name {
					color: @black;

					span {
						font-size: 12px;
					}
				}

				.approve-trade-shift-date {
					color: @dark-grey;
				}

				&.approve-trade-receiver {
					justify-content: end;
					text-align: right;
				}
			}

			.approve-trade-buttons {
				@space-between: 12px;
				.row;
				margin: 20px 0 0 0;
				justify-content: flex-end;
				gap: @space-between;
				width: 100%;
			}
		}

		.approve-pickup-footer {
			.border(top);
		}
	}
}
